import { sendEvent } from '~/clients/EventEmitter';
import { Icon } from '~/components/common/Icon';
import { NOTIFICATION_EVENT } from '../events';

export type Notification = {
  msg: string | JSX.Element;
  description?: string;
  icon?: React.ReactNode;
  key?: string;
  duration?: number;
};

export const notify = (
  msg: string | JSX.Element,
  type: 'success' | 'fail' | 'custom' = 'success',
  description?: string,
  icon?: React.ReactNode,
  key?: string,
  duration?: number
) => {
  const notification: Notification = {
    msg,
    description,
    duration,
    key,
  };

  switch (type) {
    case 'success':
      notification.icon = (
        <Icon name='checkmarkCircle' color='functional-green-dark' width={32} height={32} />
      );
      break;
    case 'fail':
      notification.icon = <Icon name='crossCircle' color='functional-red' width={32} height={32} />;
      break;
    case 'custom':
      notification.icon = icon;
      break;
  }

  sendEvent(NOTIFICATION_EVENT.NOTIFY, notification);
};
