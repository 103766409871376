import { RoomEvent } from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';

type StatusCode = {
  OK: 0;
  CANCELLED: 1;
  UNKNOWN: 2;
  INVALID_ARGUMENT: 3;
  DEADLINE_EXCEEDED: 4;
  NOT_FOUND: 5;
  ALREADY_EXISTS: 6;
  PERMISSION_DENIED: 7;
  RESOURCE_EXHAUSTED: 8;
  FAILED_PRECONDITION: 9;
  ABORTED: 10;
  OUT_OF_RANGE: 11;
  UNIMPLEMENTED: 12;
  INTERNAL: 13;
  UNAVAILABLE: 14;
  DATA_LOSS: 15;
  UNAUTHENTICATED: 16;
};

export const STATUS_CODE: StatusCode = {
  OK: 0,
  CANCELLED: 1,
  UNKNOWN: 2,
  INVALID_ARGUMENT: 3,
  DEADLINE_EXCEEDED: 4,
  NOT_FOUND: 5,
  ALREADY_EXISTS: 6,
  PERMISSION_DENIED: 7,
  RESOURCE_EXHAUSTED: 8,
  FAILED_PRECONDITION: 9,
  ABORTED: 10,
  OUT_OF_RANGE: 11,
  UNIMPLEMENTED: 12,
  INTERNAL: 13,
  UNAVAILABLE: 14,
  DATA_LOSS: 15,
  UNAUTHENTICATED: 16,
};

export type SocketEvent = {
  result: RoomEvent.AsObject;
};

function appendListToKeys(obj: any): any {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      acc[`${key}List`] = value.map((item) =>
        typeof item === 'object' && item !== null ? appendListToKeys(item) : item
      );
    } else if (typeof value === 'object' && value !== null) {
      acc[key] = appendListToKeys(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as any);
}

export const jsonToProto = (jsonEvent: string): SocketEvent => {
  const data = JSON.parse(jsonEvent) as SocketEvent;
  const dataLowerCase = Object.entries(data.result).reduce(
    (acc, [key, value]) => {
      //recursively append `List` to array keys
      const sanitizedValue = appendListToKeys(value);

      // handle lowercase keys due to plugin generated types
      acc.result[key.toLowerCase() as keyof RoomEvent.AsObject] = sanitizedValue as any;

      return acc;
    },
    {
      result: {},
    } as SocketEvent
  );
  return dataLowerCase;
};
