import { usePrivy } from '@privy-io/react-auth';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { browserName, isMobile } from 'react-device-detect';
import useCheckTabFocus from '~/hooks/tab/useCheckTabFocus';
import { useAutoJoinCommunity } from '~/hooks/user-experience/useAutoJoinCommunity';
import { useMigrateAuthSelector } from '~/hooks/auth/useMigrateAuthSelector';
import { LayoutAuthFlow } from '../layouts/LayoutAuthFlow';
import BrowserUnsupportedModal from '../modals/BrowserUnsupportedModal';
import DeviceUnsupportedModal from '../modals/DeviceUnsupportedModal';
import { SupportedBrowsers } from '~/utils/constants';

interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = () => {
  const router = useRouter();
  const { ready, user, isModalOpen } = usePrivy();

  const {
    id: userId,
    login,
    username,
    isReady,
    isLoadingUser,
  } = useMigrateAuthSelector(['id', 'login', 'username', 'isReady', 'isLoadingUser']);

  const inFocus = useCheckTabFocus();

  const { autoJoinCommunity, checkCanAutoJoin } = useAutoJoinCommunity(userId);

  const [isNavigating, setIsNavigating] = useState(false);

  // Call login immediately when the component mounts
  useEffect(() => {
    if (
      !isLoadingUser &&
      ready &&
      !user &&
      !isModalOpen &&
      !isMobile &&
      SupportedBrowsers.includes(browserName)
    ) {
      login();
    }
  }, [login, isLoadingUser, ready, user, isModalOpen]);

  useEffect(() => {
    if (!isNavigating && isReady && username && inFocus) {
      setIsNavigating(true);

      if (checkCanAutoJoin()) {
        autoJoinCommunity();
      } else {
        router.push(router.query.redirectTo ? (router.query.redirectTo as string) : '/dashboard');
      }
    }
  }, [router, username, isReady, autoJoinCommunity, checkCanAutoJoin, isNavigating, inFocus]);

  return (
    <LayoutAuthFlow>
      <BrowserUnsupportedModal />
      <DeviceUnsupportedModal />
    </LayoutAuthFlow>
  );
};
