import {
  CommunityMetricUpdateEvent as CommunityMetricUpdateEventGRPC,
  ConnectedUserEvent as ConnectedUserEventGRPC,
  FailedMatchConnectionEvent as FailedMatchConnectionEventGRPC,
  GameFailedToStartEvent as GameFailedToStartEventGRPC,
  GameInProgressEvent as GameInProgressEventGRPC,
  GamePendingEvent as GamePendingEventGRPC,
  GameStartedEvent as GameStartedEventGRPC,
  HostEndMatchEvent as HostEndMatchEventGRPC,
  MatchDistributionEvent as MatchDistributionEventGRPC,
  MatchEndedEvent as MatchEndedEventGRPC,
  MatchGroupEndedEvent as MatchGroupEndedEventGRPC,
  NewHostEvent as NewHostEventGRPC,
  PlayersDownloadingEvent as PlayersDownloadingEventGRPC,
  RoomCreatedEvent as RoomCreatedEventGRPC,
  RoomDeletedEvent as RoomDeletedEventGRPC,
  RoomUpdatedEvent as RoomUpdatedEventGRPC,
  UserJoinedEvent as UserJoinedEventGRPC,
  UserJoinedRoomEvent as UserJoinedRoomEventGRPC,
  UserLeftEvent as UserLeftEventGRPC,
  UserLeftRoomEvent as UserLeftRoomEventGRPC,
  UserStateUpdateEvent as UserStateUpdateEventGRPC,
  CommunitySubscriptionEvent as CommunitySubscriptionEventGRPC,
} from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';
import { TeamConfigEvent as TeamConfigEventGRPC } from '@ig/platform_sdk_web/src/modules/team_selection/events_pb';
import { User } from '~/types/AccessManagement';
import { EmptyObject } from '~/types/UtilityTypes';
import { Notification } from '~/utils/features/notification';

// ------------------ ROOM_EVENT
export enum ROOM_EVENT {
  SUBSCRIBED = 'ROOM_EVENT_SUBSCRIBED',
  UNSUBSCRIBED = 'ROOM_EVENT_UNSUBSCRIBED',
  RECONNECT = 'ROOM_EVENT_RECONNECT',
  USER_JOINED = 'ROOM_EVENT_USER_JOINED',
  USER_LEFT = 'ROOM_EVENT_USER_LEFT',
  CONFIG_UPDATE = 'ROOM_EVENT_CONFIG_UPDATE',
  NEW_HOST = 'ROOM_EVENT_NEW_HOST',
  ROOM_CLOSED = 'ROOM_EVENT_ROOM_CLOSED',
  GAME_PENDING = 'ROOM_EVENT_GAME_PENDING', // show loading screen, starting server in background
  GAME_IN_PROGRESS = 'ROOM_EVENT_GAME_IN_PROGRESS', // starting game and users join
  GAME_FAILED_TO_START = 'ROOM_EVENT_GAME_FAILED_TO_START',
  HOST_END_MATCH = 'ROOM_EVENT_HOST_END_MATCH',
  CANCEL_GAME = 'ROOM_EVENT_CANCEL_GAME', // cancel game before it starts
  MATCH_GROUP_ENDED = 'ROOM_EVENT_MATCH_GROUP_ENDED',
  MATCH_ENDED = 'ROOM_EVENT_MATCH_ENDED',
  MATCH_DISTRIBUTION_UPDATE = 'ROOM_EVENT_MATCH_DISTRIBUTION_UPDATE',
  FAILED_MATCH_CONNECTION = 'ROOM_EVENT_FAILED_MATCH_CONNECTION',
  GAME_STARTED = 'ROOM_EVENT_GAME_STARTED',
  PLAYERS_DOWNLOADING = 'ROOM_EVENT_PLAYERS_DOWNLOADING',
  TEAM_CONFIG = 'ROOM_EVENT_TEAM_CONFIG',
  USER_STATE_UPDATE = 'ROOM_EVENT_USER_STATE_UPDATE',
  NEW_ROOM_OPENED = 'ROOM_EVENT_NEW_ROOM_OPENED',
}

export enum TOURNAMENT_EVENT {
  /**
   * This event is sent when the tournament config is updated
   * e.g. when adding a round, starting the tournament, etc.
   */
  CONFIG_UPDATE = 'TOURNAMENT_EVENT_UPDATE_CONFIG',

  /**
   * This event is sent when the tournament scores are updated
   */
  SCORES_UPDATE = 'TOURNAMENT_EVENT_UPDATE_SCORES',
}

// ------------------ UNITY_EVENT
export enum UNITY_EVENT {
  TRIGGER_FOCUS_GAME = 'UNITY_EVENT_TRIGGER_FOCUS_GAME', // to let unity know to focus the game window
  TOGGLE_MUTE_GAME = 'UNITY_EVENT_TOGGLE_MUTE_GAME', // to let unity know to mute the game
  TOGGLE_FULLSCREEN_GAME = 'UNITY_EVENT_TOGGLE_FULLSCREEN_GAME', // to let unity know to toggle fullscreen
  TRIGGER_LEAVE_GAME = 'UNITY_EVENT_TRIGGER_LEAVE_GAME', // to let unity know to leave the game
}

// ------------------ GAME_EVENT
/**
 * These events are sent from the unity instance
 * @note Do not rename these enums, these strings are used in the unity client
 */
export enum GAME_EVENT {
  /**
   *  This event is sent when you connect to the game server
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  CONNECTED = 'CONNECTED',

  /**
   *  This event updates the count of how many players are still not connected to the game server
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS',

  /**
   *  This event is sent when the game is starting after every has joined or the join period has ended
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  MATCH_STARTING = 'MATCH_STARTING',

  /**
   *  This event is sent when the game is over
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  GAME_OVER = 'GAME_OVER',

  /**
   *  This event is sent when the user fails to connect to the game server
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  GAME_SERVER_CONNECTION_FAILED = 'GAME_SERVER_CONNECTION_FAILED',

  /**
   *  This event is sent when the user leaves the game by themself
   * @note DO NOT RENAME THIS EVENT, these exact strings are used in the unity client
   */
  GAME_LEFT = 'GAME_LEFT',

  /**
   *  This event forwards a key down event from the game
   *  @note { key: <event.key> } from https://www.toptal.com/developers/keycode / or MDN Docs
   */
  GAME_KEY_DOWN = 'GAME_KEY_DOWN',

  /**
   *  This event forwards a key up event from the game
   *  @note { key: <event.key> } from https://www.toptal.com/developers/keycode / or MDN Docs
   */
  GAME_KEY_UP = 'GAME_KEY_UP',

  /**
   * this event is sent when the user fails to connect to the game server after 3 failed connections
   *  and needs to be switched to the websockets relayer
   */
  SWITCH_TO_RELAY = 'SWITCH_TO_RELAY',

  /**
   * this event is sent when a relayed user connects successfully without the ws relayer
   */
  REMOVE_RELAYER = 'REMOVE_RELAYER',
}

// ------------------ AUTH_EVENT
export enum AUTH_EVENT {
  USER_IS_READY = 'AUTH_EVENT_USER_IS_READY',
}

// ------------------ COMMUNITY_EVENT
export enum COMMUNITY_EVENT {
  METRICS_UPDATE = 'COMMUNITY_EVENT_METRICS_UPDATE',
  ROOM_CREATED = 'COMMUNITY_EVENT_ROOM_CREATED',
  ROOM_UPDATED = 'COMMUNITY_EVENT_ROOM_UPDATED',
  ROOM_DELETED = 'COMMUNITY_EVENT_ROOM_DELETED',
  USER_JOINED_ROOM = 'COMMUNITY_EVENT_USER_JOINED_ROOM',
  USER_LEFT_ROOM = 'COMMUNITY_EVENT_USER_LEFT_ROOM',
  CONNECTED = 'COMMUNITY_EVENT_COMMUNITY_CONNECTED',
}

// ------------------ COMMUNITY_SUBSCRIPTION_EVENT
export enum COMMUNITY_SUBSCRIPTION_EVENT {
  COMMUNITY_SUBSCRIPTION_EVENT_UNKNOWN = 'COMMUNITY_SUBSCRIPTION_EVENT_UNKNOWN',
  COMMUNITY_SUBSCRIPTION_EVENT_ACTIVATED = 'COMMUNITY_SUBSCRIPTION_EVENT_ACTIVATED',
  COMMUNITY_SUBSCRIPTION_EVENT_OVERDUE = 'COMMUNITY_SUBSCRIPTION_EVENT_OVERDUE',
  COMMUNITY_SUBSCRIPTION_EVENT_OVERCAPACITY = 'COMMUNITY_SUBSCRIPTION_EVENT_OVERCAPACITY',
  COMMUNITY_SUBSCRIPTION_EVENT_UNDERCAPACITY = 'COMMUNITY_SUBSCRIPTION_EVENT_UNDERCAPACITY',
}

// ------------------ NOTIFICATION_EVENT
export enum NOTIFICATION_EVENT {
  NOTIFY = 'NOTIFICATION_EVENT_NOTIFY',
  CONNECTING_USER = 'NOTIFICATION_EVENT_CONNECTING_USER',
  CONNECTING_TO_ROOM = 'NOTIFICATION_EVENT_CONNECTING_TO_ROOM',
}

// ------------------ TYPES
export type EventMap = {
  [ROOM_EVENT.SUBSCRIBED]: SubscribedToRoomEvent;
  [ROOM_EVENT.UNSUBSCRIBED]: UnsubscribedFromRoomEvent;
  [ROOM_EVENT.RECONNECT]: ReconnectEvent;
  [ROOM_EVENT.USER_JOINED]: UserJoinedEvent;
  [ROOM_EVENT.USER_LEFT]: UserLeftEvent;
  [ROOM_EVENT.CONFIG_UPDATE]: ConfigUpdateEvent;
  [ROOM_EVENT.NEW_HOST]: NewHostEvent;
  [ROOM_EVENT.ROOM_CLOSED]: RoomClosedEvent;
  [ROOM_EVENT.NEW_ROOM_OPENED]: NewRoomOpenedEvent;
  [ROOM_EVENT.GAME_PENDING]: GamePendingEvent;
  [ROOM_EVENT.GAME_IN_PROGRESS]: GameInProgressEvent;
  [ROOM_EVENT.GAME_FAILED_TO_START]: GameFailedToStartEvent;
  [ROOM_EVENT.HOST_END_MATCH]: HostEndMatchEvent;
  [ROOM_EVENT.CANCEL_GAME]: CancelGameEvent;
  [ROOM_EVENT.MATCH_GROUP_ENDED]: MatchGroupEndedEvent;
  [ROOM_EVENT.MATCH_ENDED]: MatchEndedEvent;
  [ROOM_EVENT.MATCH_DISTRIBUTION_UPDATE]: MatchDistributionEvent;
  [ROOM_EVENT.FAILED_MATCH_CONNECTION]: FailedMatchConnectionEvent;
  [ROOM_EVENT.GAME_STARTED]: GameStartedEvent;
  [ROOM_EVENT.PLAYERS_DOWNLOADING]: PlayersDownloadingEvent;
  [ROOM_EVENT.TEAM_CONFIG]: TeamConfigEvent;
  [ROOM_EVENT.USER_STATE_UPDATE]: UserStateUpdateEvent;

  [TOURNAMENT_EVENT.CONFIG_UPDATE]: TournamentConfigUpdateEvent;
  [TOURNAMENT_EVENT.SCORES_UPDATE]: TournamentScoresEvent;

  [UNITY_EVENT.TRIGGER_FOCUS_GAME]: TriggerFocusGameEvent;
  [UNITY_EVENT.TRIGGER_LEAVE_GAME]: TriggerLeaveGameEvent;
  [UNITY_EVENT.TOGGLE_MUTE_GAME]: ToggleMuteGameEvent;
  [UNITY_EVENT.TOGGLE_FULLSCREEN_GAME]: ToggleFullscreenGameEvent;

  [GAME_EVENT.CONNECTED]: GameConnectedEvent;
  [GAME_EVENT.GAME_OVER]: GameOverEvent;
  [GAME_EVENT.GAME_SERVER_CONNECTION_FAILED]: GameServerConnectionFailedEvent;
  [GAME_EVENT.MATCH_STARTING]: MatchStartingEvent;
  [GAME_EVENT.WAITING_FOR_PLAYERS]: WaitingForPlayersEvent;
  [GAME_EVENT.GAME_LEFT]: GameLeftEvent;
  [GAME_EVENT.GAME_KEY_DOWN]: GameKeyEvent;
  [GAME_EVENT.GAME_KEY_UP]: GameKeyEvent;
  [GAME_EVENT.SWITCH_TO_RELAY]: SwitchToRelayEvent;
  [GAME_EVENT.REMOVE_RELAYER]: RemoveRelayerEvent;
  [COMMUNITY_EVENT.METRICS_UPDATE]: CommunityMetricUpdateEvent;
  [COMMUNITY_EVENT.ROOM_CREATED]: RoomCreatedEvent;
  [COMMUNITY_EVENT.ROOM_UPDATED]: RoomUpdatedEvent;
  [COMMUNITY_EVENT.ROOM_DELETED]: RoomDeletedEvent;
  [COMMUNITY_EVENT.USER_JOINED_ROOM]: UserJoinedRoomEvent;
  [COMMUNITY_EVENT.USER_LEFT_ROOM]: UserLeftRoomEvent;
  [COMMUNITY_EVENT.CONNECTED]: ConnectedUserEvent;

  [COMMUNITY_SUBSCRIPTION_EVENT.COMMUNITY_SUBSCRIPTION_EVENT_UNKNOWN]: CommunitySubscriptionEvent;
  [COMMUNITY_SUBSCRIPTION_EVENT.COMMUNITY_SUBSCRIPTION_EVENT_ACTIVATED]: CommunitySubscriptionEvent;
  [COMMUNITY_SUBSCRIPTION_EVENT.COMMUNITY_SUBSCRIPTION_EVENT_OVERDUE]: CommunitySubscriptionEvent;
  [COMMUNITY_SUBSCRIPTION_EVENT.COMMUNITY_SUBSCRIPTION_EVENT_OVERCAPACITY]: CommunitySubscriptionEvent;
  [COMMUNITY_SUBSCRIPTION_EVENT.COMMUNITY_SUBSCRIPTION_EVENT_UNDERCAPACITY]: CommunitySubscriptionEvent;

  [NOTIFICATION_EVENT.NOTIFY]: NotificationEvent;
  [NOTIFICATION_EVENT.CONNECTING_USER]: NotificationConnectingUserEvent;
  [NOTIFICATION_EVENT.CONNECTING_TO_ROOM]: NotificationConnectingToRoomEvent;

  [AUTH_EVENT.USER_IS_READY]: UserIsReadyEvent;
};

// ---------- PAYLOADS

// Room Events
export type SubscribedToRoomEvent = EmptyObject;
export type UnsubscribedFromRoomEvent = { communitySlug: string; roomSlug: string };
export type ReconnectEvent = { communitySlug: string; roomSlug: string };
export type UserJoinedEvent = UserJoinedEventGRPC.AsObject;
export type UserLeftEvent = UserLeftEventGRPC.AsObject;
export type MatchDistributionEvent = MatchDistributionEventGRPC.AsObject;
export type PlayersDownloadingEvent = PlayersDownloadingEventGRPC.AsObject;
export type ConfigUpdateEvent = { config: Record<string, string> }; // dont use ConfigUpdateEventGRPC.AsObject, cannot be native map<string,string> because websockets send this data via json and both need to be compatible
export type GamePendingEvent = GamePendingEventGRPC.AsObject;
export type GameInProgressEvent = GameInProgressEventGRPC.AsObject;
export type HostEndMatchEvent = HostEndMatchEventGRPC.AsObject;
export type GameFailedToStartEvent = GameFailedToStartEventGRPC.AsObject;
export type MatchEndedEvent = MatchEndedEventGRPC.AsObject;
export type MatchGroupEndedEvent = MatchGroupEndedEventGRPC.AsObject;
export type FailedMatchConnectionEvent = FailedMatchConnectionEventGRPC.AsObject;
export type GameStartedEvent = GameStartedEventGRPC.AsObject;
export type NewHostEvent = NewHostEventGRPC.AsObject;
export type RoomClosedEvent = EmptyObject;
export type NewRoomOpenedEvent = EmptyObject;
export type CancelGameEvent = EmptyObject;
export type GameServerConnectionFailedEvent = EmptyObject;
export type TeamConfigEvent = TeamConfigEventGRPC.AsObject;
export type UserStateUpdateEvent = UserStateUpdateEventGRPC.AsObject;

// Tournament Events
export type TournamentConfigUpdateEvent = EmptyObject;
export type TournamentScoresEvent = EmptyObject;

// Game Instance Events
export type TriggerFocusGameEvent = EmptyObject;
export type TriggerLeaveGameEvent = EmptyObject;
export type ToggleMuteGameEvent = { mute: boolean };
export type ToggleFullscreenGameEvent = { fullscreen: boolean };

// Community Events
export type CommunityMetricUpdateEvent = CommunityMetricUpdateEventGRPC.AsObject;
export type RoomCreatedEvent = RoomCreatedEventGRPC.AsObject;
export type RoomDeletedEvent = RoomDeletedEventGRPC.AsObject;
export type RoomUpdatedEvent = RoomUpdatedEventGRPC.AsObject;
export type ConnectedUserEvent = ConnectedUserEventGRPC.AsObject;
export type UserJoinedRoomEvent = UserJoinedRoomEventGRPC.AsObject;
export type UserLeftRoomEvent = UserLeftRoomEventGRPC.AsObject;
export type CommunitySubscriptionEvent = CommunitySubscriptionEventGRPC.AsObject;

// Game Events
export type GameOverEvent = EmptyObject;
export type GameConnectedEvent = {
  waitingForPlayersTotal: number;
};
export type WaitingForPlayersEvent = {
  waitingForPlayersRemaining: number;
};
export type MatchStartingEvent = {
  startingInSeconds: number;
};
export type GameLeftEvent = EmptyObject;
export type GameKeyEvent = { key: string }; // event.key from: https://www.toptal.com/developers/keycode / or MDN Docs
export type SwitchToRelayEvent = EmptyObject;
export type RemoveRelayerEvent = EmptyObject;
// Notification Events
export type NotificationEvent = Notification;
export type NotificationConnectingUserEvent = boolean;
export type NotificationConnectingToRoomEvent = boolean;

// Auth Events
export type UserIsReadyEvent = { user: User };
