import { CommunityType } from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';
import { getCookie, deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { AnalyticsClientSingleton } from '~/clients/AnalyticsClient';
import { AccessManagementClient } from '~/clients/AccessManagementClient';
import { AUTO_JOIN_COMMUNITY, COOKIE_KEYS } from '~/utils/constants';
import { STATUS_CODE } from '~/utils/grpc/grpc';
import { baseUrl } from '~/utils/framework/nextjs';
import { notify } from '~/utils/features/notification';

export const useAutoJoinCommunity = (userId: string | undefined) => {
  const router = useRouter();

  const autoJoinCommunity = async () => {
    const cookieCommunity = getCookie(COOKIE_KEYS.AUTO_JOIN_COMMUNITY)?.toString() || '';
    if (cookieCommunity === '') return;

    const community = JSON.parse(cookieCommunity) as AUTO_JOIN_COMMUNITY;

    try {
      const client = AccessManagementClient.getInstance();
      await client.joinCommunity(community.id);

      // analytics
      const latestCommunityInfo = await client.getCommunityById({ id: community.id });
      const isAdmin = latestCommunityInfo.adminsList?.some((a) => a === userId);
      AnalyticsClientSingleton.getInstance().communityJoined({
        communityId: community.id,
        communityName: community.name,
        communityURL: `${baseUrl()}/x/${community.slug}`,
        activeSeats: community.activeSeats || 0,
        inviteMethod: community.inviteMethod,
        role: isAdmin ? 'admin' : 'member',
        communityType:
          latestCommunityInfo.communityType === CommunityType.COMMUNITY_TYPE_COMPANY
            ? 'company'
            : 'community',
      });

      await router.push(
        router.query.redirectTo ? (router.query.redirectTo as string) : `/x/${community.slug}`
      );
      notify(`You joined ${community.name}`, 'success');
    } catch (e) {
      console.error('error', e);
      if ((e as any).code === STATUS_CODE.PERMISSION_DENIED) {
        router.push(`/x/${community.slug}/join?error=permission-denied`);
      }
      if ((e as any).code === STATUS_CODE.ALREADY_EXISTS) {
        router.push(`/x/${community.slug}`);
      }
    } finally {
      deleteCookie(COOKIE_KEYS.AUTO_JOIN_COMMUNITY);
    }
  };

  const checkCanAutoJoin = () => {
    const cookieCommunity = getCookie(COOKIE_KEYS.AUTO_JOIN_COMMUNITY)?.toString() || '';
    return cookieCommunity ? true : false;
  };

  return { autoJoinCommunity, checkCanAutoJoin };
};
