export const baseUrl = () => window?.location?.origin || '';

export const isSSR = () => typeof window === 'undefined';

export function extractSlugFromPathname(url: string) {
  const parts = url.split('/');
  const xIndex = parts.indexOf('x');

  if (xIndex !== -1 && xIndex + 1 < parts.length) {
    return parts[xIndex + 1];
  }

  return null;
}
