import mitt, { Emitter } from 'mitt';
import { EventMap } from '~/utils/events';

type Events = Record<string, any>;

const eventEmitter: Emitter<Events> = mitt<Events>();

export const sendEvent = <K extends keyof EventMap>(eventName: K, eventData: EventMap[K]) => {
  eventEmitter.emit(eventName, eventData);
};

export default eventEmitter;
