import { User as PrivyUser } from '@privy-io/react-auth';
import { toAlphaNumeric } from './string/strings';
import { OptionsType } from 'cookies-next/lib/types';
import { IS_LOCAL_ENV } from './constants';
import { setCookie } from 'cookies-next';

// ------------------------------------------------- getTempUsernameFromExternalId

export const getEmailFromPrivyUser = (user: PrivyUser) => {
  const email = user.email?.address;
  const googleEmail = user.google?.email;
  const appleEmail = user.apple?.email;
  const discordEmail = user.discord?.email;

  return email || googleEmail || appleEmail || discordEmail || '';
};

// ------------------------------------------------- getTempUsernameFromExternalId

export const getTempUsernameFromExternalId = (externalId: string) =>
  toAlphaNumeric(externalId).substring(externalId.length - 16);

// ------------------------------------------------- refreshAccessToken

export const refreshAccessToken = async () => {
  try {
    const token = await (await import('@privy-io/react-auth')).getAccessToken();
    if (!token) {
      throw new Error('Refresh token failed');
    }

    let options: OptionsType | undefined = {};
    if (!IS_LOCAL_ENV) {
      options = {
        secure: true,
        sameSite: 'none',
      };
    }
    setCookie('privy:token', token, options);
    setCookie('ws:token', token, { ...options, domain: `.internet.game` }); // this is used to authenticate the websocket connection
  } catch (e) {
    console.warn('Refresh auth token failed', e);
  }
};
